@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply font-jost font-bold text-negroC
        text-[24px] leading-[32px] text-center
        smAux:text-[32px] smAux:leading-[40px]  md:text-left
        lg:text-[34px] lg:leading-[42px]
        xl:text-[48px] xl:leading-[56px]
    }

    h2 {
        @apply font-jost font-bold text-negroC 
        text-[24px] leading-[32px] 
        sm:text-[32px] sm:leading-[48px] 
        xl:text-[40px] xl:leading-[48px]
    }

    h3 {
        @apply font-jost font-bold 
        text-[18px] leading-[24px] 
        smAux:text-[20px] smAux:leading-[28px] 
        sm:text-[24px] sm:leading-[32px]
    }

    h4 {
        @apply font-jost font-bold 
        text-[14px] leading-[16px] 
        smAux:text-[16px] smAux:leading-[18px] 
        sm:text-[18px] sm:leading-[22px]
    }

    p {
        @apply font-sans font-[400]
    }

    input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        outline: none;
    }

    img {
        @apply select-none
    }
}

@layer components {

    .p1 {
        @apply text-center md:text-left text-[14px] smAux:text-[16px] xl:text-[20px]
    }

    .p2 {
        @apply font-sans font-normal text-[14px] sm:text-[15px]
    }

    .p3{
        @apply font-jost text-[16px] smAux:text-[20px] 
        mdAux2:font-bold mdAux2:text-[22px] lg:text-[24px]
    }

    .h2S {
        @apply font-jost font-bold 
        text-[24px] smAux:text-[32px] mdAux2:text-[28px] lg:text-[40px]
    }

    .textSer{
        @apply text-[16px] smAux:text-[15px] mdAux2:text-[20px] lg:text-[16px]
        leading-[24px]
    }

    .h2blog {
        @apply !font-bold sm:!mt-[10px]
        !text-[20px] sm:!text-[32px]
    }

    @media screen and (min-width: 240px) {
        .swiper-pagination-bullet {
            width: 8px !important;
            height: 8px !important;
        }
    }

    @media screen and (min-width: 360px) {
        .swiper-pagination-bullet {
            width: 12px !important;
            height: 12px !important;
        }
    }

    @media screen and (min-width: 500px) {
        .swiper-pagination-bullet {
            width: 16px !important;
            height: 16px !important;
        }
    }
}


@layer utilities {
    .containerNav {
        @apply w-[263px] mx-auto 
        280:w-full 280:px-[5%] 
        xl:max-w-[1200px] xl:px-0
    }

    .containerW-1200 {
        @apply max-w-[1200px] mx-auto px-[5%] 
        smAux:px-[8%] sm:px-[5%] xl:px-0
    }

    .containerBlog2{
        @apply max-w-[780px] mx-auto px-[5%] 
        sm:px-[6%] auxHero:px-0
    }

    .container {
        @apply w-[263px] mx-auto 280:w-full 280:px-[6%] xl:max-w-[1200px] xl:px-0
    }

    .containerBlog {
        @apply !w-full mx-auto px-[5%] lg:max-w-[1180px] xl:px-0
    }

    .textNav {
        @apply font-jost font-medium text-negroC cursor-pointer 
        text-[16px] px-[16px] py-[12px] hover:text-[#FF0062] 
        smAux:text-[20px] mdAux2:text-[24px] mdAux2:font-bold
        lgAux:text-[20px] lgAux:py-0 lgAux:font-medium
        transition-all ease-in select-none
    }

    .container-article {
        @apply w-full px-[6%] mx-auto
            md:max-w-[780px] md:px-[4%] lg:px-0
    }

    .btnpagination {
        @apply  rounded-full cursor-pointer transition-all ease-in-out
        py-2 pl-2 pr-1 hover:bg-gray-100 select-none
    }

}